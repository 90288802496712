import type { ReactElement, ReactNode } from 'react';
import {
  FormattedMessage as ReactIntlFormattedMessage,
  useIntl,
  IntlFormatters,
  IntlProvider as ReactIntlProvider
} from 'react-intl';
import type { Props as ReactIntlFormattedMessageProps } from 'react-intl/src/components/message';
import ptBR from '@/locales/pt-BR.json';

export type FormattedMessageKeyType = keyof typeof ptBR;

type FormatMessageArgs = Parameters<IntlFormatters['formatMessage']>;

type FormattedMessageProps = ReactIntlFormattedMessageProps<Record<string, ReactNode>> & {
  id: FormattedMessageKeyType;
};

export const useFormattedMessage = () => {
  const { formatMessage } = useIntl();

  const typedFormatMessage = (
    descriptor: FormatMessageArgs[0] & {
      id: FormattedMessageKeyType;
    },
    values?: FormatMessageArgs[1],
    options?: FormatMessageArgs[2]
  ) => formatMessage(descriptor, values, options);

  return {
    formatMessage: typedFormatMessage
  };
};

export const FormattedMessage = ({ id, values }: FormattedMessageProps) => (
  <ReactIntlFormattedMessage id={id} values={values} />
);

type IntlProviderProps = {
  children: ReactNode;
};

export const IntlProvider = ({ children }: IntlProviderProps): ReactElement => (
  <ReactIntlProvider locale="pt-BR" messages={ptBR}>
    {children}
  </ReactIntlProvider>
);
