import { createSlice } from '@reduxjs/toolkit';
import { initialStateDataUser, dataUserType, dataVisitedPage } from '@/interfaces/IDataUser';

const dataUserSlice = createSlice({
  name: 'dataUser',
  initialState: initialStateDataUser,
  reducers: {
    resetDataUser: (state: dataUserType) => {
      Object.assign(state, initialStateDataUser);
    },
    addNewVisitedPages: (state, { payload }: { payload: dataVisitedPage }) => ({
      ...state,
      visitedPages: [...state.visitedPages, payload]
    })
  }
});

export const { resetDataUser, addNewVisitedPages } = dataUserSlice.actions;

export default dataUserSlice;
